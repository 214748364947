var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "v-row",
        {
          staticClass: "mt-6 mx-0",
          staticStyle: { width: "100%" },
          attrs: { justify: "center" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "4", lg: "4" } },
            [
              _c("p", { staticClass: "text-h5 text-center" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ]),
              _c("ProgressCard", {
                attrs: {
                  score: _vm.getCategoryRisk,
                  color: _vm.riskColor,
                  text: _vm.$t("burnoutReportSummary.severity." + _vm.riskText),
                  widthFactor: 1.5,
                  sizeFactor: _vm.progressSizeFactor,
                  burnout: ""
                }
              }),
              _c(
                "p",
                {
                  staticClass:
                    "text-body-1 font-weight-medium mx-4 text-center",
                  attrs: { id: "description" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "burnoutReportDetailsCards." +
                            _vm.category +
                            ".description"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "12", lg: "8" } },
                    [
                      !_vm.showDetails
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "showDetailsButton",
                                color: "primary",
                                width: "100%"
                              },
                              on: {
                                click: function($event) {
                                  _vm.showDetails = true
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.showDetails")))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showDetails && _vm.$vuetify.breakpoint.smAndDown
            ? _c("category-explanation", {
                attrs: { category: _vm.category, riskText: _vm.riskText },
                on: {
                  close: function($event) {
                    _vm.showDetails = false
                  }
                }
              })
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "8", lg: "8" } },
            [
              _c(
                "p",
                {
                  staticClass: "text-h5",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "text-center mt-4"
                    : "ml-11"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("burnoutReportPanelTitles.recommendations")
                      ) +
                      " "
                  )
                ]
              ),
              _c("recommendations-content", {
                staticStyle: { height: "90%" },
                attrs: {
                  assessmentId: _vm.assessmentId,
                  results: _vm.results,
                  category: _vm.category,
                  cards: _vm.cards
                }
              })
            ],
            1
          ),
          _vm.showDetails && _vm.$vuetify.breakpoint.mdAndUp
            ? _c("category-explanation", {
                attrs: { category: _vm.category, riskText: _vm.riskText },
                on: {
                  close: function($event) {
                    _vm.showDetails = false
                  }
                }
              })
            : _vm._e(),
          _vm._l(_vm.warnings, function(warning) {
            return _c(
              "v-col",
              { key: warning, attrs: { cols: "11" } },
              [
                _c(
                  "v-row",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-4 mb-2",
                        attrs: { large: "", color: "red" }
                      },
                      [_vm._v("warning")]
                    ),
                    _c("span", {
                      staticClass: "mb-0",
                      domProps: { innerHTML: _vm._s(warning) }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }