<template>
  <div style="width: 100%">
    <v-row class="mt-6 mx-0" style="width: 100%" justify="center">
      <v-col cols="12" sm="12" md="4" lg="4">
        <p class="text-h5 text-center">
          {{ title }}
        </p>
        <ProgressCard
          :score="getCategoryRisk"
          :color="riskColor"
          :text="$t('burnoutReportSummary.severity.' + riskText)"
          :widthFactor="1.5"
          :sizeFactor="progressSizeFactor"
          burnout
        />
        <p
          id="description"
          class="text-body-1 font-weight-medium mx-4 text-center"
        >
          {{ $t("burnoutReportDetailsCards." + category + ".description") }}
        </p>
        <v-row justify="center">
          <v-col cols="12" sm="6" md="12" lg="8">
            <v-btn
              id="showDetailsButton"
              color="primary"
              width="100%"
              @click="showDetails = true"
              v-if="!showDetails"
              >{{ $t("buttons.showDetails") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <category-explanation
        v-if="showDetails && $vuetify.breakpoint.smAndDown"
        :category="category"
        :riskText="riskText"
        @close="showDetails = false"
      />
      <v-col cols="12" sm="12" md="8" lg="8">
        <p
          class="text-h5"
          :class="$vuetify.breakpoint.smAndDown ? 'text-center mt-4' : 'ml-11'"
        >
          {{ $t("burnoutReportPanelTitles.recommendations") }}
        </p>
        <recommendations-content
          :assessmentId="assessmentId"
          :results="results"
          :category="category"
          :cards="cards"
          style="height: 90%"
        />
      </v-col>
      <category-explanation
        v-if="showDetails && $vuetify.breakpoint.mdAndUp"
        :category="category"
        :riskText="riskText"
        @close="showDetails = false"
      />
      <v-col cols="11" v-for="warning in warnings" :key="warning">
        <v-row align="center">
          <v-icon large color="red" class="mr-4 mb-2">warning</v-icon>
          <span class="mb-0" v-html="warning"></span>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgressCard from "@/components/common/report/cards/ProgressCard";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import CategoryValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues.json";
import RecommendationsContent from "./RecommendationsContent.vue";
import BurnoutAssessmentDetailsCards from "@/assets/json/BurnoutAssessment/BurnoutAssessmentDetailsCards.json";
import CategoryExplanation from "./CategoryExplanation";

export default {
  name: "CategoryBreakdown",
  props: {
    category: String,
    scores: Object,
    results: Object,
    assessmentId: String,
    cards: Array
  },
  components: {
    ProgressCard,
    RecommendationsContent,
    CategoryExplanation
  },
  data() {
    return {
      riskColor: null,
      riskText: null,
      showDetails: false,
      warnings: [],
      detailsCards: BurnoutAssessmentDetailsCards
    };
  },
  created() {
    this.setRiskTextAndColor();
    if (this.category === "secondarysymptoms") {
      this.warnings = this.getSecondarySymptomsWarningText();
    }
  },
  computed: {
    title() {
      return this.$t("burnoutReportDetailsCards." + this.category + ".title");
    },
    getCategoryRisk() {
      return BurnoutScores.getRiskPercentage(this.scores[this.category]);
    },
    progressSizeFactor() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 0.9;
        case "lg":
          return 1.1;
        default:
          return 1.6;
      }
    }
  },
  methods: {
    setRiskTextAndColor() {
      let score = this.scores[this.category];
      if (score >= CategoryValues[this.category].veryhigh) {
        this.riskText = "veryHigh";
        this.riskColor = "vitrueRed";
      } else if (score >= CategoryValues[this.category].high) {
        this.riskText = "high";
        this.riskColor = "vitrueYellow";
      } else if (score >= CategoryValues[this.category].average) {
        this.riskText = "average";
        this.riskColor = "vitrueBrightGreen";
      } else {
        this.riskText = "low";
        this.riskColor = "vitrueDarkGreen";
      }
    },
    getSecondarySymptomsWarningText() {
      let text = [];
      let warnings = this.detailsCards.secondarysymptoms.warnings;
      let results = this.results.secondarysymptoms;
      let frequencies = ["Never", "Rarely", "Sometimes", "Often", "Always"];
      let genericWarningSymptoms = [];

      Object.keys(results).forEach(symptom => {
        if (!warnings[symptom]) {
          return;
        }

        // Get symptom frequency and associated warning
        let frequency = frequencies[results[symptom]];
        let warning = warnings[symptom][frequency];
        if (warning) {
          // If the warning is flagged as generic, store the symptom to be used later,
          // otherwise, push the symtom's warning in full.
          if (warning === "GENERIC") {
            genericWarningSymptoms.push(this.$t(warnings[symptom].description));
          } else {
            text.push(this.$t(warning));
          }
        }
      });

      // Splice sypmtoms that need a generic health warning into the generic message
      if (genericWarningSymptoms.length > 0) {
        let connector = this.$t("burnoutReportDetailsCards.general.and");

        let genericWarning = this.$t(warnings.genericwarning).replace(
          "*SYMPTOMS*",
          genericWarningSymptoms
            .join(", ")
            .replace(/, ([^,]*)$/, " " + connector + " $1")
        );
        text.push(genericWarning);
      }
      return text;
    }
  }
};
</script>
