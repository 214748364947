<template>
  <v-expand-transition>
    <v-row style="width: 100%" justify="center" class="px-6 my-3">
      <v-col cols="12" sm="12" md="6">
        <v-row align="center" :justify="isMobile ? 'center' : 'start'">
          <v-img
            contain
            max-width="50px"
            src="@/assets/images/burnoutassessment/informationIcon.png"
            :class="isMobile ? 'mb-2' : ''"
          />
          <v-col cols="12" sm="10">
            <p
              id="detailsDefineTitle"
              class="ml-3 mb-0 text-body-1 font-weight-medium"
              :class="isMobile ? 'text-center' : ''"
            >
              {{
                $t("burnoutReportDetailsCards.general.overviewTitle", {
                  "0": title
                })
              }}
            </p>
          </v-col>
        </v-row>
        <p id="detailsDefineBody" class="text-body-1 mt-3">
          {{ $t("burnoutReportDetailsCards." + category + ".text.overview") }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-row align="center" :justify="isMobile ? 'center' : 'start'">
          <v-img
            contain
            max-width="50px"
            src="@/assets/images/burnoutassessment/chartIcon.png"
            :class="isMobile ? 'mb-2' : ''"
          />
          <v-col cols="12" sm="10">
            <p
              id="detailsExplainTitle"
              class="ml-3 mb-0 text-body-1 font-weight-medium"
              :class="isMobile ? 'text-center' : ''"
            >
              {{
                $t("burnoutReportDetailsCards.general.explanationTitle", {
                  "0": title
                })
              }}
            </p>
          </v-col>
        </v-row>
        <p id="detailsExplainBody" class="text-body-1 mt-3">
          {{
            $t(
              "burnoutReportDetailsCards." +
                category +
                ".text.personalised." +
                riskText.toLowerCase()
            )
          }}
        </p>
      </v-col>
      <v-btn
        id="hideDetailsButton"
        color="primary"
        text
        @click="$emit('close')"
        >{{ $t("buttons.hideDetails") }}</v-btn
      >
    </v-row>
  </v-expand-transition>
</template>

<script>
export default {
  name: "CategoryExplanation",
  props: {
    category: String,
    riskText: String
  },
  computed: {
    title() {
      return this.$t("burnoutReportDetailsCards." + this.category + ".title");
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    }
  }
};
</script>
