var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expand-transition",
    [
      _c(
        "v-row",
        {
          staticClass: "px-6 my-3",
          staticStyle: { width: "100%" },
          attrs: { justify: "center" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "6" } },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: _vm.isMobile ? "center" : "start"
                  }
                },
                [
                  _c("v-img", {
                    class: _vm.isMobile ? "mb-2" : "",
                    attrs: {
                      contain: "",
                      "max-width": "50px",
                      src: require("@/assets/images/burnoutassessment/informationIcon.png")
                    }
                  }),
                  _c("v-col", { attrs: { cols: "12", sm: "10" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "ml-3 mb-0 text-body-1 font-weight-medium",
                        class: _vm.isMobile ? "text-center" : "",
                        attrs: { id: "detailsDefineTitle" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "burnoutReportDetailsCards.general.overviewTitle",
                                { "0": _vm.title }
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass: "text-body-1 mt-3",
                  attrs: { id: "detailsDefineBody" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "burnoutReportDetailsCards." +
                            _vm.category +
                            ".text.overview"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "6" } },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: _vm.isMobile ? "center" : "start"
                  }
                },
                [
                  _c("v-img", {
                    class: _vm.isMobile ? "mb-2" : "",
                    attrs: {
                      contain: "",
                      "max-width": "50px",
                      src: require("@/assets/images/burnoutassessment/chartIcon.png")
                    }
                  }),
                  _c("v-col", { attrs: { cols: "12", sm: "10" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "ml-3 mb-0 text-body-1 font-weight-medium",
                        class: _vm.isMobile ? "text-center" : "",
                        attrs: { id: "detailsExplainTitle" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "burnoutReportDetailsCards.general.explanationTitle",
                                { "0": _vm.title }
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass: "text-body-1 mt-3",
                  attrs: { id: "detailsExplainBody" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "burnoutReportDetailsCards." +
                            _vm.category +
                            ".text.personalised." +
                            _vm.riskText.toLowerCase()
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { id: "hideDetailsButton", color: "primary", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("buttons.hideDetails")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }