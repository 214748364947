var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-2", attrs: { raised: !_vm.burnout, flat: _vm.burnout } },
    [
      _c(
        "v-row",
        {
          staticClass: "pa-5 fill-height",
          attrs: { "no-gutters": "", justify: "center" }
        },
        [
          _vm.title
            ? _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "text-h5 mb-4 text-center",
                    style:
                      _vm.burnout && !_vm.$vuetify.breakpoint.xs
                        ? "min-height:70px;"
                        : ""
                  },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                )
              ])
            : _vm._e(),
          _c(
            "v-col",
            { class: _vm.progressTextSize, attrs: { cols: "12" } },
            [
              _vm.burnout
                ? _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-progress-circular",
                        {
                          style: _vm.circularProgressStyle,
                          attrs: {
                            rotate: 270,
                            size: _vm.progressSize,
                            value: _vm.scoreCircularValue,
                            width: _vm.progressWidth,
                            color: _vm.color
                          }
                        },
                        [_vm._v(_vm._s(_vm.score) + "%")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.burnout
                ? _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c("DeskAssessmentResultVisual", {
                        attrs: { gaugeWidth: _vm.gaugeWidth, score: _vm.score }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.text
                ? _c("div", {
                    staticClass: "mt-4 text-center",
                    class: _vm.burnout
                      ? "text-h4 " + _vm.color + "--text"
                      : "text-h6",
                    domProps: { innerHTML: _vm._s(_vm.text) }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }