export default {
  convertSize(vuetifySize, factor) {
    switch (vuetifySize) {
      case "xs":
        return 100 * factor;
      case "sm":
        return 100 * factor;
      case "md":
        return 150 * factor;
      case "lg":
        return 200 * factor;
      case "xl":
        return 250 * factor;
      default:
        return 250 * factor;
    }
  },
  convertWidth(vuetifySize, factor) {
    switch (vuetifySize) {
      case "xs":
        return 5 * factor;
      case "sm":
        return 8 * factor;
      case "md":
        return 10 * factor;
      case "lg":
        return 15 * factor;
      case "xl":
        return 15 * factor;
      default:
        return 15 * factor;
    }
  },
  getTextSize(progressSize) {
    if (progressSize < 150) {
      return "text-h5";
    } else if (progressSize < 250) {
      return "text-h4";
    } else {
      return "text-h3";
    }
  }
};
