<template>
  <v-card :raised="!burnout" :flat="burnout" class="my-2">
    <v-row no-gutters class="pa-5 fill-height" justify="center">
      <v-col cols="12" v-if="title">
        <div
          class="text-h5 mb-4 text-center"
          :style="burnout && !$vuetify.breakpoint.xs ? 'min-height:70px;' : ''"
        >
          {{ title }}
        </div>
      </v-col>
      <v-col cols="12" :class="progressTextSize">
        <v-row justify="center" v-if="burnout">
          <v-progress-circular
            :rotate="270"
            :size="progressSize"
            :value="scoreCircularValue"
            :width="progressWidth"
            :color="color"
            :style="circularProgressStyle"
            >{{ score }}%</v-progress-circular
          >
        </v-row>
        <v-row justify="center" v-if="!burnout">
          <DeskAssessmentResultVisual :gaugeWidth="gaugeWidth" :score="score" />
        </v-row>
        <div
          v-if="text"
          class="mt-4 text-center"
          :class="burnout ? 'text-h4 ' + color + '--text' : 'text-h6'"
          v-html="text"
        ></div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ProgressSizeConverter from "@/services/converters/circular-progress-size-converter";
import DeskAssessmentResultVisual from "@/components/deskassessment/report/content/DeskAssessmentResultVisual.vue";

export default {
  name: "ProgressCard",
  components: {
    DeskAssessmentResultVisual
  },
  props: {
    title: String,
    score: Number,
    text: String,
    color: String,
    sizeFactor: {
      type: Number,
      default: 1
    },
    widthFactor: {
      type: Number,
      default: 1
    },
    burnout: Boolean
  },
  computed: {
    scoreCircularValue() {
      return parseInt(this.score);
    },
    progressSize() {
      return ProgressSizeConverter.convertSize(
        this.$vuetify.breakpoint.name,
        this.sizeFactor
      );
    },
    progressWidth() {
      return ProgressSizeConverter.convertWidth(
        this.$vuetify.breakpoint.name,
        this.widthFactor
      );
    },
    progressTextSize() {
      return ProgressSizeConverter.getTextSize(this.progressSize);
    },
    circularProgressStyle() {
      return this.burnout ? "stroke-linecap: round" : "";
    },
    gaugeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 250;
        case "lg":
          return 325;
        default:
          return 400;
      }
    }
  }
};
</script>
