<template>
  <div>
    <v-row v-if="category">
      <category-breakdown
        :category="category"
        :cards="cards"
        :results="results"
        :scores="scores"
        :assessmentId="assessmentId"
      />
    </v-row>
    <v-row v-else>
      <v-col cols="12" lg="7" v-if="topRowCategories[0]">
        <InformationCard
          id="topHorizontalCard"
          class="fill-height"
          :sideHeader="true"
          :color="categoryColor(topRowCategories[0])"
          :titleScore="getPercentageText(topRowCategories[0])"
          :title="$t(detailsCards[topRowCategories[0]].title)"
          :imageUrl="detailsCards[topRowCategories[0]].imageUrl"
          :text="getText(topRowCategories[0])"
        />
      </v-col>

      <v-col cols="12" lg="5" v-if="topRowCategories[1]">
        <InformationCard
          id="topVerticalCard"
          class="fill-height"
          :sideHeader="false"
          :color="categoryColor(topRowCategories[1])"
          :titleScore="getPercentageText(topRowCategories[1])"
          :title="$t(detailsCards[topRowCategories[1]].title)"
          :imageUrl="detailsCards[topRowCategories[1]].imageUrl"
          :text="getText(topRowCategories[1])"
        />
      </v-col>

      <v-col cols="12"
        ><InformationCard
          id="centerHorizontalCard"
          class="fill-height"
          :sideHeader="true"
          :title="$t(detailsCards.mentaldistance.title)"
          :titleScore="getPercentageText('mentaldistance')"
          :color="categoryColor('mentaldistance')"
          :imageUrl="detailsCards.mentaldistance.imageUrl"
          :text="getText('mentaldistance')"
      /></v-col>

      <v-col cols="12" lg="7" v-if="bottomRowCategories[0]">
        <InformationCard
          id="bottomHorizontalCard"
          class="fill-height"
          :sideHeader="true"
          :color="categoryColor(bottomRowCategories[0])"
          :titleScore="getPercentageText(bottomRowCategories[0])"
          :title="$t(detailsCards[bottomRowCategories[0]].title)"
          :imageUrl="detailsCards[bottomRowCategories[0]].imageUrl"
          :text="getText(bottomRowCategories[0])"
          :warningText="
            bottomRowCategories[0] === 'secondarysymptoms'
              ? getSecondarySymptomsWarningText()
              : []
          "
        />
      </v-col>

      <v-col cols="12" lg="5" v-if="bottomRowCategories[1]">
        <InformationCard
          id="bottomVerticalCard"
          class="fill-height"
          :sideHeader="false"
          :color="categoryColor(bottomRowCategories[1])"
          :titleScore="getPercentageText(bottomRowCategories[1])"
          :title="$t(detailsCards[bottomRowCategories[1]].title)"
          :imageUrl="detailsCards[bottomRowCategories[1]].imageUrl"
          :text="getText(bottomRowCategories[1])"
          :warningText="
            bottomRowCategories[1] === 'secondarysymptoms'
              ? getSecondarySymptomsWarningText()
              : []
          "
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InformationCard from "@/components/common/report/cards/InformationReportCard";
import BurnoutAssessmentDetailsCards from "@/assets/json/BurnoutAssessment/BurnoutAssessmentDetailsCards.json";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import CategoryValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues.json";
import CategoryBreakdown from "./CategoryBreakdown";

export default {
  name: "DetailsContent",
  components: {
    InformationCard,
    CategoryBreakdown
  },
  props: {
    results: Object,
    assessmentId: String,
    category: String,
    cards: Array
  },
  data() {
    return {
      detailsCards: BurnoutAssessmentDetailsCards,
      scores: BurnoutScores.getScores(this.results)
    };
  },
  watch: {
    results() {
      this.scores = BurnoutScores.getScores(this.results);
    }
  },
  computed: {
    topRowCategories() {
      let exhaustionLength = 0;
      let cognitiveimpairmentLength = 0;

      this.getText("exhaustion").forEach(s => {
        exhaustionLength += s.length;
      });

      this.getText("cognitiveimpairment").forEach(s => {
        cognitiveimpairmentLength += s.length;
      });

      if (exhaustionLength < cognitiveimpairmentLength) {
        return ["cognitiveimpairment", "exhaustion"];
      }
      return ["exhaustion", "cognitiveimpairment"];
    },
    bottomRowCategories() {
      let secondarySymptomsLength = 0;
      let emotionalimpairmentLength = 0;

      this.getText("secondarysymptoms").forEach(s => {
        secondarySymptomsLength += s.length;
      });
      this.getSecondarySymptomsWarningText().forEach(s => {
        secondarySymptomsLength += s.length;
      });

      this.getText("emotionalimpairment").forEach(s => {
        emotionalimpairmentLength += s.length;
      });

      if (secondarySymptomsLength < emotionalimpairmentLength) {
        return ["emotionalimpairment", "secondarysymptoms"];
      }
      return ["secondarysymptoms", "emotionalimpairment"];
    }
  },
  methods: {
    categoryColor(category) {
      let score = this.scores[category];
      if (score >= CategoryValues[category].veryhigh) {
        return this.$vuetify.theme.currentTheme.vitrueRed;
      } else if (score >= CategoryValues[category].high) {
        return this.$vuetify.theme.currentTheme.vitrueOrange;
      } else if (score >= CategoryValues[category].average) {
        return this.$vuetify.theme.currentTheme.vitrueYellow;
      } else {
        return this.$vuetify.theme.currentTheme.vitruePaleGreen;
      }
    },
    getText(category) {
      let score = this.scores[category];

      if (score >= CategoryValues[category].veryhigh) {
        return this.$t(this.detailsCards[category].text.veryhigh);
      } else if (score >= CategoryValues[category].high) {
        return this.$t(this.detailsCards[category].text.high);
      } else if (score >= CategoryValues[category].average) {
        return this.$t(this.detailsCards[category].text.average);
      }
      return this.$t(this.detailsCards[category].text.low);
    },
    getSecondarySymptomsWarningText() {
      let text = [];
      let warnings = this.detailsCards.secondarysymptoms.warnings;
      let results = this.results.secondarysymptoms;
      let frequencies = ["Never", "Rarely", "Sometimes", "Often", "Always"];
      let genericWarningSymptoms = [];

      Object.keys(results).forEach(symptom => {
        if (!warnings[symptom]) {
          return;
        }

        // Get symptom frequency and associated warning
        let frequency = frequencies[results[symptom]];
        let warning = warnings[symptom][frequency];
        if (warning) {
          // If the warning is flagged as generic, store the symptom to be used later,
          // otherwise, push the symtom's warning in full.
          if (warning === "GENERIC") {
            genericWarningSymptoms.push(this.$t(warnings[symptom].description));
          } else {
            text.push(this.$t(warning));
          }
        }
      });

      // Splice sypmtoms that need a generic health warning into the generic message
      if (genericWarningSymptoms.length > 0) {
        let connector = this.$t("burnoutReportDetailsCards.general.and");

        let genericWarning = this.$t(warnings.genericwarning).replace(
          "*SYMPTOMS*",
          genericWarningSymptoms
            .join(", ")
            .replace(/, ([^,]*)$/, " " + connector + " $1")
        );
        text.push(genericWarning);
      }
      return text;
    },
    getPercentageText(category) {
      return BurnoutScores.getRiskPercentage(this.scores[category]) + "%";
    }
  }
};
</script>
