var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.category
        ? _c(
            "v-row",
            [
              _c("category-breakdown", {
                attrs: {
                  category: _vm.category,
                  cards: _vm.cards,
                  results: _vm.results,
                  scores: _vm.scores,
                  assessmentId: _vm.assessmentId
                }
              })
            ],
            1
          )
        : _c(
            "v-row",
            [
              _vm.topRowCategories[0]
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "7" } },
                    [
                      _c("InformationCard", {
                        staticClass: "fill-height",
                        attrs: {
                          id: "topHorizontalCard",
                          sideHeader: true,
                          color: _vm.categoryColor(_vm.topRowCategories[0]),
                          titleScore: _vm.getPercentageText(
                            _vm.topRowCategories[0]
                          ),
                          title: _vm.$t(
                            _vm.detailsCards[_vm.topRowCategories[0]].title
                          ),
                          imageUrl:
                            _vm.detailsCards[_vm.topRowCategories[0]].imageUrl,
                          text: _vm.getText(_vm.topRowCategories[0])
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.topRowCategories[1]
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "5" } },
                    [
                      _c("InformationCard", {
                        staticClass: "fill-height",
                        attrs: {
                          id: "topVerticalCard",
                          sideHeader: false,
                          color: _vm.categoryColor(_vm.topRowCategories[1]),
                          titleScore: _vm.getPercentageText(
                            _vm.topRowCategories[1]
                          ),
                          title: _vm.$t(
                            _vm.detailsCards[_vm.topRowCategories[1]].title
                          ),
                          imageUrl:
                            _vm.detailsCards[_vm.topRowCategories[1]].imageUrl,
                          text: _vm.getText(_vm.topRowCategories[1])
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("InformationCard", {
                    staticClass: "fill-height",
                    attrs: {
                      id: "centerHorizontalCard",
                      sideHeader: true,
                      title: _vm.$t(_vm.detailsCards.mentaldistance.title),
                      titleScore: _vm.getPercentageText("mentaldistance"),
                      color: _vm.categoryColor("mentaldistance"),
                      imageUrl: _vm.detailsCards.mentaldistance.imageUrl,
                      text: _vm.getText("mentaldistance")
                    }
                  })
                ],
                1
              ),
              _vm.bottomRowCategories[0]
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "7" } },
                    [
                      _c("InformationCard", {
                        staticClass: "fill-height",
                        attrs: {
                          id: "bottomHorizontalCard",
                          sideHeader: true,
                          color: _vm.categoryColor(_vm.bottomRowCategories[0]),
                          titleScore: _vm.getPercentageText(
                            _vm.bottomRowCategories[0]
                          ),
                          title: _vm.$t(
                            _vm.detailsCards[_vm.bottomRowCategories[0]].title
                          ),
                          imageUrl:
                            _vm.detailsCards[_vm.bottomRowCategories[0]]
                              .imageUrl,
                          text: _vm.getText(_vm.bottomRowCategories[0]),
                          warningText:
                            _vm.bottomRowCategories[0] === "secondarysymptoms"
                              ? _vm.getSecondarySymptomsWarningText()
                              : []
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.bottomRowCategories[1]
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "5" } },
                    [
                      _c("InformationCard", {
                        staticClass: "fill-height",
                        attrs: {
                          id: "bottomVerticalCard",
                          sideHeader: false,
                          color: _vm.categoryColor(_vm.bottomRowCategories[1]),
                          titleScore: _vm.getPercentageText(
                            _vm.bottomRowCategories[1]
                          ),
                          title: _vm.$t(
                            _vm.detailsCards[_vm.bottomRowCategories[1]].title
                          ),
                          imageUrl:
                            _vm.detailsCards[_vm.bottomRowCategories[1]]
                              .imageUrl,
                          text: _vm.getText(_vm.bottomRowCategories[1]),
                          warningText:
                            _vm.bottomRowCategories[1] === "secondarysymptoms"
                              ? _vm.getSecondarySymptomsWarningText()
                              : []
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }